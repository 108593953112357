import React, { Component } from 'react';
import {Col, Row} from 'antd'
import './styles/index.less';


export default class Footer extends Component {

  render() {
    return (
      <div className="foot">
        {/* <div className='footer-main'>
          <Row gutter={20}>
            <Col span={6}>
              <h3>链接</h3>
              <a href='http://www.moa.gov.cn/' target='_blank'>中华人民共和国农业农村部</a>
              <a href="http://www.mee.gov.cn/" target="_blank">中华人民共和国生态环境部</a>
              <a>鱼道监测系统</a>
              <a href="http://www.sinoeco.net" target="_blank">中科生态</a>
            </Col>
            <Col span={6}>
              <h3>关于</h3>
              <p>关于我们</p>
              <p>联系我们</p>
              <p>电话：13908201305</p>
              <p>邮箱：support@wh-eco.com</p>
            </Col>
            <Col span={6}>
              <h3>合作伙伴</h3>
              <p>中国华能集团有限公司</p>
              <p>中国电建集团</p>

            </Col>
            <Col span={6}>
              <img src={require('../../assets/images/qrCode.png')}  alt="二维码" />
            </Col>
          </Row>
        </div> */}
          <div className="footer-bottom">
          ©2022 易科捷（武汉）生态科技有限公司
          <p>
          <a target="_blank" href='https://beian.miit.gov.cn'>
            鄂ICP备17015229号-1
                <img src={require('../../assets/images/wd.png')} />
            </a>
          </p>
          </div>
      </div>
    );
  }
}
