import request from '../utils/request';
import { ctx} from '../common/global';

export function getNewsContent(payload) {
  let key = payload.key
  return request(`${ctx}getNewsContent?key=${key}`)
}
export function getCaseContent(payload) {
  let id = payload.id
  return request(`${ctx}getCaseContent?id=${id}`)
}
