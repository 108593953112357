import React, { Component } from 'react';
import { connect } from 'dva'
import { Row, Col } from 'antd'
import './styles/index.less';

class AboutPage extends Component {

  render() {

    return (
      <div className='about'>
        <div className="about-banner">
          <img src={require('../../assets/images/aboutUS.png')} alt="product" />
        </div>
        <div className="introduction ">
          <h1>公司简介</h1>
          <h2>OUR PROFILE</h2>
          <div className='line'></div>
          <div className="intr-content">
          易科捷（武汉）生态科技有限公司成立于2017年年初，隶属于武汉中科瑞华生态科技股份有限 公司，是一家致力于过鱼设施、过鱼效果监测、集运鱼系统的技术研发、产品设备、 技术服务于一体
          的民营高科技公司。 公司扎根技术研究，不仅创制出优质的硬件设备，且配套自己的在线监测软件，提供准确全面的数据，更好地满足客户的需求、服务于生态文明建设。
          公司拥有国家发明专利、自主知识产权，目前有鱼道观鱼设备、电赶拦鱼设备、鱼道在线监测系统、水质在线监测系统等。
          </div>
        </div>
        <div className="service">
          <div className="box">
            <div className="service-title">
              <h1>业务领域</h1>
              <h2>OUR PROFILE</h2>
              <div className='line'></div>
            </div>
            <div className="service-content">
            易科捷（武汉）生态科技有限公司成立于2017年年初，隶属于武汉中科瑞华生态科技股份有限公司，是一家致力于过鱼设施、过鱼效果监测、集运鱼系统的技术研发、产品设备、 技术服务于一体 的民营高科技公司。
            公司扎根技术研究，不仅创制出优质的硬件设备，且配套自己的在线监测软件，提供准确全面的 数据，更好地满足客户的需求、服务于生态文明建设。公司拥有国家发明专利、自主知识产权，
            目前有鱼道观鱼设备、电赶拦鱼设备、鱼道在线监测系统、水质在线监测系统等。
            </div>
          </div>
        </div>
        <div className="achievement">
          <h1>科研成果</h1>
          <h2>ACHIEVEMENT</h2>
          <div className='line'></div>
          <Row gutter={20}>
            {/* <Col span={12}><img src={require("../../assets/images/111.png")} alt='' /></Col> */}
            <Col span={12}><img src={require("../../assets/images/222.png")} alt='' /></Col>
            <Col span={12}><img src={require("../../assets/images/333.png")} alt='' /></Col>
            <Col span={12}><img src={require("../../assets/images/444.png")} alt='' /></Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default connect(({ about }) => ({
  about
}))(AboutPage);
