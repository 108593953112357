// import * as api from '../services/home';
export default {
  namespace: 'product',
  state: {
    loading: true,
    data: {
      stationIntroduction: '',
      drainageIntroduction: '',
      valleyIntroduction: '',
      drainageImg: '',
      valleyImg: ''
    }
  },
  effects: {
    // *getGCXXData({ payload }, { call, put }) {
    //   const data = yield call(api.getGCXXData, {})
    //   yield put({
    //     type: "saveData",
    //     payload: {
    //       data
    //     }
    //   });
    // }
  },
  reducers: {
    // 'saveData'(state, { payload }) {
    //   return Object.assign({}, state, {
    //     "loading": false,
    //     "data": payload.data
    //   })
    // }
  },
}