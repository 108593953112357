import * as api from '../services/home';
export default {
  namespace: 'home',
  state: {
    newsArr:[]
  },
  effects: {
    *get3News({ payload }, { call, put }) {
      const data = yield call(api.get3News, {})
      let items = []
      if(data.status){
        items = data
      }else{
        items = {
          data:[]
        }
      }
      yield put({
        type: "saveData",
        payload: {
          items
        }
      });
    }
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "newsArr": payload.items.data
      })
    }
  },
}