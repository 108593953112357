import React, { Component } from 'react';
import { connect } from 'dva'
import './styles/index.less';
import { Row, Col } from 'antd';

class ContactPage extends Component {

  render() {

    return (
      <div className="contactPage">
        <div className="contact-banner">
          <img src={require('../../assets/images/contact.png')} alt="case" />
        </div>
        <div className="tips">
          任何相关合作事宜或技术支持请通过以下方式联系我们
        </div>
        <div className="contact-main">
          <div className="name">易科捷（武汉）生态科技有限公司</div>
          <div className="adr">公司地址：武汉市洪山区和平街道万盈国际A栋18楼1802</div>
          <div className="map">
            <img src={require('../../assets/images/map.jpg')} alt="地图" />
          </div>
          <Row gutter={5}>
            <Col span={12}>
              <div className="box">
                <img src={require('../../assets/images/customservice.png')} alt="客服热线" />
                <p className="title">客服热线</p>
                <p className="phoneNum">15927580006</p>
              </div>
            </Col>
            <Col span={12}>
              <div className="box">
                <img src={require('../../assets/images/complain.png')} alt="投诉建议" />
                <p className="title">投诉建议</p>
                <p className="phoneNum">15927580006</p>
              </div>
            </Col>
            <Col span={12}>
              <div className="box">
                <img src={require('../../assets/images/cooperation.png')} alt="商务合作" />
                <p className="title">商务合作</p>
                <p className="phoneNum">15927580006</p>
              </div>
            </Col>
            <Col span={12}>
              <div className="box">
                <img src={require('../../assets/images/email.png')} alt="Email" />
                <p className="title">Email</p>
                <p className="phoneNum">zhuting@sinoeco.net</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default connect(({ contact }) => ({
  contact
}))(ContactPage);
