import IndexPage from '../pages/Home'
import About from '../pages/About'
import Contact from '../pages/Contact';
import Statement from '../pages/Statement';

const navList = [
  {
    title: '首页',
    route: '/index',
    component: IndexPage
  },
  {
    title: '关于我们',
    route: '/about',
    component: About
  },
  {
    title: '联系我们',
    route: '/contact',
    component: Contact
  },
  {
    title:'声明',
    route:'/statement',
    component: Statement
  }
]

export default navList
