import React, { Component } from 'react';
import { connect } from 'dva'
// import { Carousel, Row, Col } from 'antd';
import './styles/index.less';

class StatementPage extends Component {
  componentDidMount(){  
  }
  componentWillUnmount() {   
  }

  render() {
    return (
      <div className="statement">
         <div className="statement-banner">
          <img src={require('../../assets/images/aboutUS.png')} alt="product" />
        </div>
        <div className='neirong'>
          <h2>关于任伟、胥贤等员工离职的声明</h2>
          <p>
            我公司原员工任伟（离职时间：2024年3月31日）、胥贤（离职时间：2024年3月31日）、梁洁（离职时间：2024年9月18日），以上人员因个人原因向公司提出离职申请，并正式解除劳动合同关系。
          </p>
          <p>
            公司郑重声明：上述员工自离职之日起，其对外进行的任何业务或服务活动均不代表我公司，一切与我公司相关的事项请与黄骁联系（联系电话：15997403117）。
          </p>
          <p>给您带来不便，敬请谅解！</p>
          <p>特此声明。</p>
          <img src={require('../../assets/images/statement.jpg')}></img>
          <p className='right'>易科捷（武汉）生态科技有限公司</p>
          <p className='right'>2024年11月28日</p>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <h2>关于陈军校、黄烈勇等员工的离职声明</h2>
          <p>
          我公司原员工黄烈勇（离职时间：2021年7月20日）、陈军校（离职时间：2021年12月15日）、曾建（离职时间：2022年2月22日）、顾新武（离职时间：2022年3月2日），均因个人原因从本公司离职，感谢以上员工一直以来对公司的付出与努力。
          </p>
          <p>
            在此，我公司郑重声明，上述员工自离职之日起，其对外进行的任何业务或服务活动均不代表我公司，与我公司一切相关的事项宜请与黄骁联系（联系电话：15997403117）。
          </p>
          <p>给您带来不便，敬请谅解！</p>
          <p>特此声明。</p>
          <img src={require('../../assets/images/statement.jpg')}></img>
          <p className='right'>易科捷（武汉）生态科技有限公司</p>
          <p className='right'>2022年4月27日</p>

        </div>
      </div>
    );
  }
}
export default connect(({ statement }) => ({
  statement
}))(StatementPage);
