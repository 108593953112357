import * as api from '../services/news';
import { message } from 'antd';
export default {
  namespace: 'news',
  state: {
    newsList:[],
    totalPage:0,
    hotWord:[]
  },
  effects: {
    *getNews({ payload }, { call, put }) {
      const data = yield call(api.getNews, payload)
      let items = []
      let totalPage = 0
      if(data.status){
        items = data.data
        totalPage = data.totalPage
      }else{
        items = []
        totalPage = 0
        message.error("暂无数据！")
      }
      yield put({
        type: "saveData",
        payload: {
          items,
          totalPage
        }
      });
    },
    *searchNews({ payload }, { call, put }) {
      const data = yield call(api.searchNews, payload)
      let items = []
      let totalPage = 0
      if(data.status){
        items = data.data
        totalPage = data.totalPage
      }else{
        totalPage = 0
        items = []
        message.error("暂无数据！")
      }
      yield put({
        type: "saveData",
        payload: {
          items,
          totalPage
        }
      });
    },
    *getHotWord({payload}, {call, put}) {
      const data = yield call(api.getHotWord)
      let items = ''
      if(data.status){
        items = data
      }else{
        items= {
          data:[]
        }
      }
      yield put({
        type:"saveTag",
        payload:{
          items
        }
      })
    }
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "newsList": payload.items,
        "totalPage":payload.totalPage
      })
    },
    "saveTag"(state,{ payload }) {
      return Object.assign({}, state, {
        "hotWord":payload.items.data
      })
    }
  },
}