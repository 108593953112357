import * as api from '../services/home';
export default {
  namespace: 'statement',
  state: {
    data:[]
  },
  effects: {
   
  },
  reducers: {
   
  },
}