import * as api from '../services/casePage';
import { message } from 'antd';
export default {
  namespace: 'casePage',
  state: {
    caseList:[],
    totalPage:0
  },
  effects: {
    *getCaseList({ payload }, { call, put }) {
      const data = yield call(api.getCaseList, payload)
      let items = []
      let totalPage = 0
      if(data.status){
        items = data.data
        totalPage = data.totalPage
      }else{
        items = []
        totalPage = 0
        message.error("暂无数据！")
      }
      yield put({
        type: "saveData",
        payload: {
          items,
          totalPage
        }
      });
    }
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "caseList": payload.items,
        "totalPage":payload.totalPage
      })
    }
  },
}