import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import history from './common/history';
import nav from './common/nav';
import HeaderInfo from './components/HeaderInfo/HeaderInfo';
import Footer from './components/Footer'
import './common/global.less';
// import Login from './pages/Login'

export default class AppLayout extends Component {
  getRoute = nav => {
    return nav.map(ele => {
      // debugger
      // if (ele.route === '/newsDetail' || ele.route === '/caseDetail') return;
      if (ele.child) {
        return this.getRoute(ele.child);
      }
      if (ele.component) {
        return (
          <Route
            key={ele.route}
            exact
            path={ele.route}
            component={ele.component}
          />
        );
      }
    });
  };
  // getMenuItem = nav => {
  //   return nav.map((ele) => {
  //     return <CustomNavlink key={ele.route}  route={ele.child ? ele.child[0].route : ele.route} title={ele.title} />
  //   });
  // };
  MainComponent = pathname => {
    return (
      <div className="layout">
        {/* {this.getMenuItem(nav)} */}
        <HeaderInfo />
        <div className="content">
          <div className="main">
            {(pathname === '/' || pathname === '/index.html') && <Redirect exact from="/" to="/index" />}
            {this.getRoute(nav)}
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  render() {
    const { pathname } = history.location;
    return this.MainComponent(pathname);
  }
}
