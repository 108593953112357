import * as api from '../services/detail';
export default {
  namespace: 'caseDetail',
  state: {
    
  },
  effects: {
    *getCaseContent({ payload }, { call, put }) {
      const data = yield call(api.getCaseContent, payload)
      yield put({
        type: "saveData",
        payload: {
          data
        }
      });
    }
  },
  reducers: {
    'saveData'(state, { payload }) {
      return Object.assign({}, state, {
        "data": payload.data.data
      })
    }
  },
}