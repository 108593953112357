import request from '../utils/request';
import { ctx} from '../common/global';

export function getNews(payload) {
  let page = payload.page
  let type = payload.type  
  return request(`${ctx}getNews/${page}?type=${type}`)
}
export function searchNews(payload) {
  let page = payload.page
  let keyword = payload.keyword
  let type = payload.type  
  return request(`${ctx}searchNews/${page}?type=${type}&keyword=${keyword}`)
}
export function getHotWord(){
  return request(`${ctx}getHotWord`)
}
