import React, { Component } from 'react';
import navList from '../../common/nav'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.png'
import './styles/index.less';


export default class HeaderInfo extends Component {
  getNavList = (item)=>{
    return item.map((ele,i)=>{
    if (ele.route === '/newsDetail' || ele.route === '/caseDetail')
     return (<li key={ele.route}>
      <Link to={ele.route}>
        {/* {ele.title} */}
      </Link>
    </li>)
    if(ele.child){
      return  <li key={ele.route}><span>{ele.title}</span><ul className="navChildUl">{this.getNavList(ele.child)}</ul> </li>
    }
    return (<li key={ele.route}>
      <Link to={ele.route}>
        {ele.title}
      </Link>
    </li>)})
  }
  render() {
    return (
      <div className="nav_container">
        <div className="contact">
          <div className="contact-main">
            <span>客服邮箱：zhuting@sinoeco.net</span>
            <span>客服热线：15927580006</span>
          </div>
        </div>
        <nav className="nav">
          <div className="logo">
            <h1>
              <a href='#'>
                <img src={Logo} alt='logo' />
              </a>
            </h1>
          </div>
          <ul className="navUl">
          {
            this.getNavList(navList)
          }
          </ul>
        </nav>
      </div>
    );
  }
}
