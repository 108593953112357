import React, { Component } from 'react';
import { connect } from 'dva'
import { Carousel, Row, Col } from 'antd';
import './styles/index.less';

class HomePage extends Component {
  componentDidMount(){
    // this.props.dispatch({
    //   type:"home/get3News"
    // })
    // window.addEventListener('scroll',this.handleScroll)
  }
  componentWillUnmount() {
    // window.removeEventListener('scroll', this.handleScroll);
  }
  // handleScroll=(event) =>{
  //   let scrollTop = document.documentElement.scrollTop
  //   let dh1 = document.getElementById("dh1")
  //   let dh2 = document.getElementById("dh2")
  //   let dh3 = document.getElementById("dh3")
  //   let dh4 = document.getElementById("dh4")
  //   console.log(scrollTop)
  //   if(scrollTop > 1350 && scrollTop < 1500){
  //     // debugger
  //     dh1.className += ' animation01'
  //     dh2.className += ' animation02'
  //     dh3.className += ' animation03'
  //     dh4.className += ' animation04'
  //   }
  //   else{
  //   // //  let a =  dh1.className
  //     dh1.remove = 'animation01'
  //     dh2.remove = 'animation02'
  //     dh3.remove = 'animation03'
  //     dh4.remove = 'animation04'
  //   }
  //   // if(scrollTop>500){
  //   //   nav.style.position = 'fixed';
  //   //   nav.style.top = '0';
  //   //   nav.style.zIndex = '9999';
  //   // }else{
  //   //   nav.style.position = 'static';
  //   // }
  //   // if(scrollTop<1000){
  //   //   this.setState({current:'ydjc'})
  //   // }
  //   // if(scrollTop>1000 && scrollTop<1500){
  //   //   this.setState({current:'ydxt'})
  //   // }
  //   // if(scrollTop>1500 && scrollTop< 2000){
  //   //   this.setState({current:'gyxq'})
  //   // }
  //   // if(scrollTop>2000){
  //   //   this.setState({current:'cslb'})
  //   // }
  // }
  render() {
    // const { newsArr } = this.props.home
    // let companyNews = ''
    // let productNews = ''
    // let industryNews = ''
    // if(newsArr.length > 0){
    //   newsArr.map((v,i) => {
    //     if(v.type === "product"){
    //       productNews = <div>
    //                       <Row gutter={20} style={{marginTop:10}}>
    //                         <Col span={18}>{v.title}</Col>
    //                         <Col span={6}>{v.date}</Col>
    //                       </Row>
    //                       <p className="newsDetails">{v.digest}</p>
    //                     </div>
    //     }
    //     if(v.type === "company"){
    //       companyNews = <div>
    //                       <Row gutter={20} style={{marginTop:10}}>
    //                         <Col span={18}>{v.title}</Col>
    //                         <Col span={6}>{v.date}</Col>
    //                       </Row>
    //                       <p className="newsDetails">{v.digest}</p>
    //                     </div>
    //     }
    //     if(v.type === "industry"){
    //       industryNews = <div>
    //                       <Row gutter={20} style={{marginTop:10}}>
    //                         <Col span={18}>{v.title}</Col>
    //                         <Col span={6}>{v.date}</Col>
    //                       </Row>
    //                       <p className="newsDetails">{v.digest}</p>
    //                     </div>
    //     }
    //   })
    // }
    return (
      <div className="homeStyle">
        <div className="banner">
          <Carousel autoplay>
            <div>
              <img src={require("../../assets/images/banner.png")} alt="banner"  />
            </div>
            <div>
              <img src={require("../../assets/images/ydjc.png")} alt="banner1"  />
            </div>
          </Carousel>
        </div>
        <div className="advantage">
            <h1>能力优势</h1>
            <h2>ABILITY ADVANTAGE</h2>
            <div className='line'></div>
            <Row style={{marginTop:10}}>
              <Col span={24}>
                <img src={require("../../assets/images/monitor.png")} alt='智能监测' />
                <h3>智能监测</h3>
                <div className='minFont'>科技连接生态</div>
              </Col>
              <Col span={24}>
                <img src={require("../../assets/images/online.png")} alt='实时在线' />
                <h3>实时在线</h3>
                <div className='minFont'>随时掌握，随处可见</div>
              </Col>
              <Col span={24}>
                <img src={require("../../assets/images/analysis.png")} alt='数据分析' />
                <h3>数据分析</h3>
                <div className='minFont'>数据支撑，准确评估</div>
              </Col>
            </Row>
        </div>
        <div className="solution">
          <div className="solutionContent">
            <h1>专业解决方案</h1>
            <h2>ABILITY ADVANTAGE</h2>
            <div className='whiteLine'></div>
            <Row style={{marginTop:40}}>
              <Col span={24}  id="dh1">
                {/* <img src={require("../../assets/images/img_solution_01.png")} alt='过鱼数据' /> */}
                <div className='describe'>
                  <h3>过鱼数据</h3>
                  <p>红外计数、高清视频影像设备，可视化实时数据展示系统，轮廓、影像、数据、统计全方位展示</p>
                </div>
              </Col>
              <Col span={24}  id="dh2">
                <div className='describe'>
                  <h3>生态调度</h3>
                  <p>高精度温度链实时监测水温数据，可视化实时数据展示系统，多种分析图表辅助分析决策</p>
                </div>
                {/* <img src={require("../../assets/images/img_solution_02.png")} alt='生态调度' /> */}
              </Col>
              <Col span={24}  id="dh3">
              {/* <img src={require("../../assets/images/img_solution_03.png")} alt='集运鱼' /> */}
                <div className='describe'>
                  <h3>集运鱼</h3>
                  <p>自动化智能升鱼机设备，自动分拣设备，维生系统运鱼车，实时监测平台，一体化集运鱼解决方案</p>
                </div>
              </Col>
              <Col span={24}  id="dh4">
                <div className='describe'>
                  <h3>集群效应监测</h3>
                  <p>高精度声呐成像设备，实时数据传输系统，动态可视化实时数据展示平台</p>
                </div>
              {/* <img src={require("../../assets/images/img_solution_04.png")} alt='集群效应监测' /> */}
              </Col>
            </Row>
          </div>
        </div>
        {/* <div className="news">
            <h1>新闻动态</h1>
            <h2>NEWS TENDENCY</h2>
            <div className='line'></div>
            <Row gutter={20} style={{marginTop:40}}>
              <Col span={8}>
                <div className="newsImg01">
                  <p className="big">产品动态</p>
                  <p className="small">功能升级 最新产品</p>
                </div>
                {productNews}
              </Col>
              <Col span={8}>
                <div className="newsImg02">
                  <p className="big">公司动态</p>
                  <p className="small">文化活动 商务合作</p>
                </div>
                {companyNews}
              </Col>
              <Col span={8}>
                <div className="newsImg03">
                  <p className="big">行业资讯</p>
                  <p className="small">行业动态 业内活动</p>
                </div>
               {industryNews}
              </Col>
            </Row>
        </div> */}
      </div>
    );
  }
}
export default connect(({ home }) => ({
  home
}))(HomePage);
